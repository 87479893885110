import * as React from "react";

import Layout from "../../components/Layout";

export default class BlogIndexPage extends React.Component {
    render() {
      return (
        <Layout>
          
        </Layout>
      );
    }
  }